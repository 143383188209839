// import { useLoaderData } from 'react-router-dom';
import React, { useState } from 'react';
import { NavLink, json, useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

function EmailList({method, events}) {
  // const events = useLoaderData();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => ({
      ...prev,
      [name]: checked, // Dynamically update the state
    }));
  };
  
  const handleSubmit = async () => {

    

    let url = apiUrl+'?request_type=delete-email-template';

    const eventData = {
      ids: checkedItems,
      _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
      request_type : 'delete-email-template',
    };

    const response = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    });
  
    // if (response.status === 422) {
    //   return response;
    // }
  
    // if (!response.ok) {
    //   throw json({ message: 'Could not delete Template.' }, { status: 500 });
    // }
    console.log('Selected Items:', checkedItems);
    navigate('/email-template?v=1');
    
  };

  return (
    <div>
      <NavLink to='new' className="btn btn-outline-primary btn-sm float-end mb-2"> Add New Email Template</NavLink>
      
      <table className="table table-stripped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Email Name/ID</th>
              <th>Date Created</th>
              <th>Last Modified</th>
              <th>Triggered Event</th>
              <th className="text-center">Manage</th>
              <th className="text-center">Duplicate</th>
            </tr>
          </thead>
          <tbody>
          {events.map((event) => (
              <tr key={event.id}>
                <td><input
                      type="checkbox"
                      name={event.id} // Use unique ID as the name
                      checked={checkedItems[event.id] || false} // Default to false if undefined
                      onChange={handleCheckboxChange}
                    /></td>
                <td><NavLink to={`edit/${event.id}`}> {event.name}</NavLink></td>
                <td>{event.createdate}</td>
                <td>{event.editdate}</td>
                <td>{event.event}</td>
                <td className="text-center">
                <NavLink to={`${event.id}`} className="btn btn-primary btn-sm"> Manage</NavLink>
                </td>
                <td className="text-center">
                <NavLink to={`duplicate/${event.id}`} className="btn btn-secondary btn-sm"> Duplicate</NavLink>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <button className="btn btn-danger btn-sm" onClick={handleSubmit}>Delete</button>
        
    </div>
  );
}

export default EmailList;


