import React, { useState, useEffect } from 'react';
import {
  Form,
  useNavigate,
  useNavigation,
  useActionData,
  json,
  redirect
} from 'react-router-dom';

import classes from './EventForm.module.css';

const apiUrl = process.env.REACT_APP_API_URL;

function EmailForm({ method, event }) {
  

  const [immediate, setImmediate] = useState(false);

  const data = useActionData();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === 'submitting';

  function cancelHandler() {
    navigate('..');
  }

  const handleCheckboxChange = () => {
    setImmediate((prev) => !prev);
  };
  
  useEffect(() => {
    if(event){
      if(event.immediate == '1'){
        setImmediate(true);
      }
    }
  }, []);

  
  return (
    <Form method={method} className={classes.form}>
      {data && data.errors && (
        <ul>
          {Object.values(data.errors).map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      )}
      <p>
        <label htmlFor="title">Email Name/ID</label>
        <input
          id="title"
          type="text"
          name="title"
          required
          defaultValue={event ? event.name : ''}
        />
      </p>
      <p>
        <label htmlFor="subject">Email Subject</label>
        <input
          id="subject"
          type="text"
          name="subject"
          required
          defaultValue={event ? event.subject : ''}
        />
      </p>
      <p>
        <label htmlFor="description">Triggered Event</label>
        <textarea
          id="description"
          name="description"
          rows="5"
          required
          defaultValue={event ? event.event : ''}
        />
      </p>
      <p className="checksel">
        <input
          id="immediate"
          type="checkbox"
          name="immediate"
          value="1"
          onChange={() => handleCheckboxChange()}
          checked={immediate ? 'checked' : ''}
          
        />
        <label htmlFor='immediate'> Email sent immediately when Triggered</label>
      </p>
      
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="frequency">Frequency</label>
          <select name="frequency" defaultValue={event ? event.frequency : ''} className="form-control" id='frequency'>
            <option value="">-select-</option>
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="FirstofMonth">First of Month</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
        <div className="col-md-4">
          <label htmlFor="reminder">Reminder</label>
          <select name="reminder" defaultValue={event ? event.reminder : ''} className="form-control" id='reminder'>
            <option value="3hrs">3 hrs</option>
            <option value="6hrs">6 hrs</option>
            <option value="12hrs">12 hrs</option>
            <option value="24hrs">24 hrs</option>
            <option value="48hrs">48 hrs</option>
            <option value="1week">1 Week</option>
          </select>
        </div>
        <div className="col-md-4">
          <label htmlFor="cronstart">Cron Start (EST)</label>
          <select name="cronstart" defaultValue={event ? event.cronstart : ''} className="form-control" id='cronstart'>
            <option value="">-select-</option>
            <option value="1">01:00</option>
            <option value="2">02:00</option>
            <option value="3">03:00</option>
            <option value="4">04:00</option>
            <option value="5">05:00</option>
            <option value="6">06:00</option>
            <option value="7">07:00</option>
            <option value="8">08:00</option>
            <option value="9">09:00</option>
            <option value="10">10:00</option>
            <option value="12">12:00</option>
            <option value="13">13:00</option>
            <option value="14">14:00</option>
            <option value="15">15:00</option>
            <option value="16">16:00</option>
            <option value="17">17:00</option>
            <option value="18">18:00</option>
            <option value="19">19:00</option>
            <option value="20">20:00</option>
            <option value="21">21:00</option>
            <option value="22">22:00</option>
            <option value="23">23:00</option>
          </select>
        </div>
      </div>
      <p>&nbsp;</p>
      <div className={classes.actions}>
        <button type="button" onClick={cancelHandler} disabled={isSubmitting}>
          Cancel
        </button>
        <button disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Save'}
        </button>
      </div>
    </Form>
  );
}

export default EmailForm;

export async function action({ request, params }) {
  const method = request.method;
  const data = await request.formData();

  const eventData = {
    title: data.get('title'),
    description: data.get('description'),
    subject: data.get('subject'),
    immediate: data.get('immediate'),
    frequency: data.get('frequency'),
    reminder: data.get('reminder'),
    cronstart: data.get('cronstart'),
    _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
    request_type : 'new-email-template',
  };

  let url = apiUrl+'?request_type=new-email-template';

  if (method === 'PATCH') {
    const id = params.id;
    eventData['tid'] = id;
    url = apiUrl+'?request_type=new-email-template&tid=' + id;
  }

  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(eventData),
  });

  if (response.status === 422) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: 'Could not save event.' }, { status: 500 });
  }

  return redirect('/email-template');
}

