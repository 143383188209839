import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import EmailTemplateList, { action as emailTemplateAction, loader as EmailList  } from './pages/EmailTemplate';
import EmailBuilder from './pages/EmailBuilder';
import NewEmailPage from './pages/NewEmail';
import EditEmailPage from './pages/EditEmail';
import { action as manipulateEmailAction } from './components/EmailForm';
import {loader as eventDetailLoader, duplicate as duplicateTemplate} from './pages/EmailDetail';

import DashboardPage from './pages/Dashboard';

import ErrorPage from './pages/Error';
import RootLayout from './pages/Root';

import AuthenticationPage, {action as authAction} from './pages/Authentication';
import { checkAuthLoader } from './util/auth';
import { action as logoutAction} from './pages/Logout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { 
        path: 'login', 
        index: true,
        element: <AuthenticationPage />, 
        action: authAction 
      },
      { 
        path: 'dashboard', 
        index: true,
        element: <DashboardPage />
      },
      {
        path: 'email-template',
        loader: checkAuthLoader,
        children: [
          {
            index: true,
            loader: EmailList,
            element: <EmailTemplateList />,
            action: emailTemplateAction,
            
          },
          {
            path: ':id',
            element: <EmailBuilder />,
            loader: checkAuthLoader,
          },
          {
            path: 'new',
            element: <NewEmailPage />,
            action: manipulateEmailAction,
            
          },
          {
            path: 'duplicate/:id',
            id: 'event-duplicate',
            element: <NewEmailPage />,
            loader: duplicateTemplate,
          },
          {
            path: 'edit/:id',
            id: 'event-detail',
            loader: eventDetailLoader,
            element: <EditEmailPage />,
            action: manipulateEmailAction,
          },
        ]
      },
      {
        path: 'logout',
        loader: logoutAction
      },
    ],
    
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
